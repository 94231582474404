<template>
  <div class="jihuo">
    <heads></heads>
    <div class="con_wrap">
      <div class="input_wrap">
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item prop="unit_name">
            <el-input v-model="form.unit_name"></el-input>
          </el-form-item>
          <el-form-item prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item prop="leader_name">
            <el-input v-model="form.leader_name"></el-input>
          </el-form-item>
          <el-form-item prop="office_phone">
            <el-input v-model="form.office_phone"></el-input>
          </el-form-item>
          <el-form-item prop="contacts">
            <el-input v-model="form.contacts"></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item prop="work_type">
            <el-select v-model="form.work_type" placeholder="请选择属性">
              <el-option label="幼儿园" value="1"></el-option>
              <el-option label="小学" value="2"></el-option>
              <el-option label="中学" value="3"></el-option>
              <el-option label="高校" value="4"></el-option>
              <el-option label="其他" value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="is_project_school">
            <el-radio-group v-model="form.is_project_school">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="account">
            <el-input v-model="form.account"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              class="go-activation"
              type="primary"
              @click="submitForm()"
              >立即激活</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import $ from "jquery";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import "@css/foot.css";
import "@css/ico.css";
import { activation } from "@api/user";
export default {
  components: {
    heads,
    foot,
  },
  data() {
    return {
      form: {
        unit_name: "",
        address: "",
        leader_name: "",
        office_phone: "",
        contacts: "",
        email: "",
        work_type: "",
        is_project_school: "",
        account: "",
        // password: "",
      },
      rules: {
        unit_name: [
          {
            required: true,
            message: "请输入单位名称",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        leader_name: [
          {
            required: true,
            message: "请输入领导姓名",
            trigger: "blur",
          },
        ],
        office_phone: [
          {
            required: true,
            message: "请输入办公室电话",
            trigger: "blur",
          },
        ],
        contacts: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        is_project_school: [
          {
            required: true,
            message: "请选择是否为示范校",
            trigger: "change",
          },
        ],
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        // password: [
        //   {
        //     required: true,
        //     message: "请输入密码",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    submitForm() {
      let that = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          activation(that.form)
            .then(function (data) {
              that.$dialog.success(data.message);
            })
            .catch(function (e) {
              that.$dialog.error(e.msg);
            });
        } else {
          return false;
        }
      });
    },
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
};
</script>

<style>
.jihuo > .con_wrap {
  width: 100%;
  height: 1516px;
  background: url(../assets/images/jihuo.jpg);
  background-size: cover;
  background-position: center 0;
}
.jihuo .input_wrap {
  width: 818px;
  height: 1090px;
  position: relative;
  top: 220px;
  left: 591px;
  /* border: 1px solid blue; */
}
.jihuo .el-form {
  width: 320px;
  height: 730px;
  position: absolute;
  top: 13.5%;
  left: 36%;
  /* border: 1px solid red; */
}

.jihuo .radio {
  width: 90%;
  height: 30px;
  background: transparent;
  border: 0;
  padding-left: 0;
  display: flex;
  position: relative;
  padding-bottom: 47px;
}

.jihuo .radio > input:nth-child(1) {
  position: absolute;
  left: -25px;
  width: 30px;
}
.jihuo .radio > input:nth-child(2) {
  position: absolute;
  left: 73px;
  width: 30px;
}
.jihuo_btn {
  width: 372px;
  height: 47px;
  border: 0;
  background: transparent;
  position: absolute;
  top: 714px;
  left: -90px;
}

.radio_type {
  width: 26px;
  height: 26px;
  appearance: none;
  position: relative;
}
.radio_type:before {
  content: "";
  width: 26px;
  height: 26px;
  border: 0;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
}
.radio_type:checked:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 0;
  background: #c59c5a;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
}
.radio_type:checked:after {
  content: "";
  width: 10px;
  height: 5px;
  border: 0;
  border-top: transparent;
  border-right: transparent;
  text-align: center;
  display: block;
  position: absolute;
  top: 6px;
  left: 5px;
  vertical-align: middle;
  transform: rotate(-45deg);
}
.radio_type:checked + label {
  color: #c59c5a;
}

.el-input__inner {
  width: 320px;
  height: 30px;
  background: transparent;
  border: none;
}
.el-form-item {
  margin-bottom: 36px;
}

.el-form-item + .el-form-item:last-child {
  margin-top: 40px;
}

.go-activation,
.go-activation:active,
.go-activation:visited,
.go-activation:hover {
  width: 300px;
  color: #fff !important;
  background-color: #c7b2a8 !important;
  border: 0.00521rem solid #c7b2a8 !important;
}
</style>